import React, { FC, useEffect, useState } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Modal, Tag, Row, Col, Switch, Button, Select, FormInstance } from 'antd'
import { EditMemberDialogFormData, HierarchyType, RoleFormData } from 'src/stores/tenants/common'
import { CheckOutlined, CloseOutlined, ExclamationCircleOutlined, } from '@ant-design/icons'
import { ApplicationWithRoles } from 'src/stores/applications/applications-store'
import './application-role-editor.less'
import { IdentityProps, withIdentity } from 'src/core/services/authentication'
import { withInfo } from 'antd/lib/modal/confirm'
const { info, error, confirm } = Modal

export enum RoleVisibility {
  OperationRequest = 1,
  ServiceManager = 2
}
interface EditApplicationsRolesProps extends WithTranslation, IdentityProps {
  value?: RoleFormData[]
  filter?: string
  applications: ApplicationWithRoles[],
  form?: FormInstance<EditMemberDialogFormData>,
  onAddingRole: (value: boolean) => void,
  onRevokeAllAccess: (appId: string) => void
  readonly?: boolean
  specialRole?: string,
  applyVisibility?: RoleVisibility

}

export const EditApplicationsRoles: FC<EditApplicationsRolesProps> = (props) => {
  const {
    t,
    value,
    applications,
    form,
    filter,
    readonly

  } = props
  const [data, setData] = useState<RoleFormData[]>()
  const [appToRevoke, setAppToRevoke] = useState<string[]>()
  const [appFilter, setFilter] = useState(filter)

  useEffect(() => {
    setData(value)
  }, [value]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFilter(filter)
  }, [filter]) // eslint-disable-line react-hooks/exhaustive-deps

  const onRoleChange = (roles: RoleFormData[]) => {
    if (form)
      form.setFieldsValue({ roles: roles })
  }


  return (

    <div> {applications.map(app => <EditApplicationRole applyVisibility={props.applyVisibility} readonly={readonly} visible={filter && filter.length > 0 ? app.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 : true} onRoleChange={onRoleChange} roles={data ? data : []} application={app} {...props} />)} </div >

  )
}


const EditApplicationRole: FC<{ roles: RoleFormData[]; applyVisibility?: RoleVisibility; specialRole?: string; readonly?: boolean; application: ApplicationWithRoles; onRoleChange: (roles: RoleFormData[]) => void, onRevokeAllAccess: (appId: string) => void, visible: boolean, onAddingRole: (value: boolean) => void } & WithTranslation & IdentityProps> = ({
  t,
  roles,
  readonly,
  application,
  onRoleChange,
  onAddingRole,
  onRevokeAllAccess,
  identity,
  visible, specialRole, applyVisibility

}) => {
  const [values, setValues] = useState<RoleFormData[]>()
  const [checked, setChecked] = useState(false)
  const [checkByHierarchy, setCheckByHierarchy] = useState(false)
  const [onAddRole, setOnAddRole] = useState(false)
  const [fullRoles, setFullRoles] = useState(false)
  const [selectedRoles, setSelectedRoles] = useState<RoleFormData[]>()
  const [IsAdmin] = useState(identity.roles?.any(r => r === "Administrator" || (specialRole && r == specialRole)))
  const [IsUser] = useState(identity.roles?.any(r => r === "User"))
  const [IsManager] = useState(identity.roles?.any(r => r === "Manager"))
  useEffect(() => {
    reload(roles)
  }, [roles]) // eslint-disable-line react-hooks/exhaustive-deps

  const reload = (value: RoleFormData[]) => {
    setValues(value)
    let checked = value.any(t => t.applicationId == application.id)
    setChecked(checked)
    let checkedByHierarchy = value.any(t => t.applicationId == application.id && t.hierarchyType != HierarchyType.Own)
    setCheckByHierarchy(checkedByHierarchy)
    setFullRoles(value.filter(t => t.applicationId == application.id).length === application.roles.length)
  }

  const handleClose = (e: any, removedTag: string) => {
    e.preventDefault()
    confirm({
      title: t('Are you sure to revoke this access?'),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t('Yes'),
      okType: 'danger',
      okButtonProps: {
        disabled: false,
      },
      cancelText: t('No'),
      onOk() {
        if (values) {
          var roles = values.filter(t => t.value !== removedTag)
          onRoleChange(roles)
        }
      },
      onCancel() {

      },
    })
  }

  const removeOwnAccess = () => {
    Modal.destroyAll()
    var roles = values ? values.filter(t => (t.applicationId == application.id && t.hierarchyType != HierarchyType.Own) || t.applicationId != application.id) : []
    onRoleChange(roles)
  }
  const revokeAllAccess = () => {
    Modal.destroyAll()
    var roles = values ? values.filter(t => t.applicationId != application.id) : []
    onRoleChange(roles)
    onRevokeAllAccess(application.id)
  }

  const checkStatus = () => {
    var check = false
    if (values) {
      check = values.any(t => t.applicationId == application.id)
    }
    setChecked(check)
  }


  const handleChange = (elements: any[]) => {

    if (elements) {
      let rolesToadd = []
      for (var i = 0; i < elements.length; i++) {
        var e = elements[i]
        var role = application.roles.find(t => t.id === e) || { name: "", id: "" }
        let roleToAdd: RoleFormData = {
          applicationId: application.id,
          applicationName: application.name,
          hierarchyType: HierarchyType.Own,
          label: role?.name,
          value: role?.id
        }
        rolesToadd.push(roleToAdd)
      }
      setSelectedRoles(rolesToadd)
    }
  }
  const addRole = () => {
    if (selectedRoles) {
      var roles = values
      for (var i = 0; i < selectedRoles.length; i++) {
        roles?.push(selectedRoles[i])
      }
      onRoleChange(roles || [])
      if (roles)
        setFullRoles(roles.filter(t => t.applicationId == application.id).length === application.roles.length)
      setOnAddRole(false)
      onAddingRole(false)
    }
  }

  const onSwitchChange = () => {
    if (!checked) {
      setChecked(true)
      setOnAddRole(true)
      onAddingRole(true)
    }

    else {
      if (checkByHierarchy) {
        var hierarchyOnly = values ? values.filter(t => (t.applicationId == application.id && t.hierarchyType == HierarchyType.Own)).length == 0 : false
        if (!IsAdmin) {
          error(
            {
              okText: t('Cancel'),
              title: t("Only Admin can revoke this access"),

              onOk() { },
            }
          )
          return
        }
        info({
          okText: t('Cancel'),
          title: hierarchyOnly ? t("Do you want to  revoke every access of the application?") : t("Do you want to revoke user access to this entity or revoke every access of the application?"),
          content: (
            <div>
              {!hierarchyOnly && <Button onClick={() => removeOwnAccess()} type="primary" style={{ margin: "5px" }}>{t("this element only")}</Button>}
              <Button disabled={!IsAdmin && readonly} onClick={() => revokeAllAccess()} style={{ margin: "5px", background: "red", color: "white" }}>{t("Revoke all access")}</Button>
            </div>
          ),
          onOk() { },
        })
        return
      }
      if (values && values.any(t => t.applicationId === application.id))
        showPropsConfirm()
      else {
        setChecked(false)
      }
    }
  }

  const showPropsConfirm = () => {
    confirm({
      title: t('Are you sure to revoke all user Access?'),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t('Yes'),
      okType: 'danger',
      okButtonProps: {
        disabled: false,
      },
      cancelText: t('No'),
      onOk() {
        if (values) {
          var roles = values.filter(t => t.applicationId != application.id)
          onRoleChange(roles || [])
        }
      },
      onCancel() {

      },
    })
  }

  const tagRender = (props: any) => {
    const { label, value, closable, onClose } = props

    return (
      <Tag closable={closable && !readonly} onClose={onClose} style={{ marginRight: 3 }}>
        {label}
      </Tag>
    )
  }

  const getVisibilityApplication = () => {
    var result = visible ? "block" : "none"
    if (applyVisibility) {
      if (applyVisibility == RoleVisibility.OperationRequest) {
        if (application.roles.any(r => r.operationRequestVisibility)) {
          return result
        }
      }
      if (applyVisibility == RoleVisibility.ServiceManager) {
        if (application.roles.filter(r => r.serviceManagerVisibility).length) {
          return result
        }
      }
      return "none"
    }
    return result
  }

  return (
    <div style={{ display: getVisibilityApplication(), width: "100%" }}>
      {application && values && <Row>
        <Col className="switch-button-container">
          {!readonly && <Switch size="default"
            onChange={() => onSwitchChange()}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={checked}
            disabled={(IsUser && !IsAdmin && !IsManager) || readonly}
            className={checkByHierarchy ? "hierarchy-switch" : checked ? "normal-switch" : ""}
          />}
          <div className={"application-description"}>
            <p> {application.name}</p>
            <span> {application.displayName}</span>
          </div>



        </Col>
      </Row>
      }
      {application && values && checked && <Row>
        <Col style={{ padding: "10px" }}>

          {!onAddRole && <>

            {
              values.filter(r => r.applicationId === application.id).map(r =>



                <Tag style={{ fontSize: "12px", padding: "2px 15px" }} key={r.value} closable={r.hierarchyType == HierarchyType.Own && !readonly} onClose={(e) => handleClose(e, r.value)} color={r.hierarchyType == HierarchyType.Own ? "cornflowerblue" : "cadetblue"}>

                  {r.label} </Tag>



              )
            }

            < br />
            {!readonly && (!fullRoles && <Button type="link" onClick={() => { setOnAddRole(true); onAddingRole(true) }} >
              {t("Add new user role")}
            </Button>)}
          </>

          }
          {onAddRole &&
            <>
              <div style={{ display: "flex" }}>
                <Select
                  mode="multiple"
                  tagRender={tagRender}
                  style={{ width: 450 }}
                  placeholder={t("Select Role")}
                  onChange={handleChange}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {application.roles.filter(role => !values.any(t => t.value === role.id)).map(app =>
                    <Select.Option key={app.id} value={app.id} disabled={app.byHierarchy || (applyVisibility && ((applyVisibility == RoleVisibility.OperationRequest && !app.operationRequestVisibility) || (applyVisibility == RoleVisibility.ServiceManager && !app.serviceManagerVisibility)))}  >
                      {app.name}
                    </Select.Option >)}
                </Select>
                <Button type="primary" icon={<CheckOutlined />} onClick={() => addRole()} >{t("Ok")}</Button>
              </div>
              <Button type="link" onClick={() => { setOnAddRole(false); onAddingRole(false); checkStatus() }} >
                {t("Cancel")}
              </Button>
            </>

          }

        </Col>

      </Row>}
    </div>
  )
}
export default withIdentity(withTranslation()(EditApplicationRole))