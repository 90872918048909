import DataStore, { Query, QueryResult } from 'src/core/stores/data-store'
import FormStore from 'src/core/stores/form-store'
import { CommandResult } from 'src/core/stores/types'
import { IdentityType } from '../tenants/common'
import { TenantMemberRoleBody } from '../tenants/tenant-members-store'
import { Guid } from 'odata-query'
import { UserItem } from '../users/users-store'


export enum OperationRequestStatus {
  WaitingForApprover = "WaitingForApprover",
  Applied = "Applied",
  Rejected = "Rejected",
  RollBack = "RollBack",
  Cancelled = "Cancelled"
}

export enum OperationType {
  PermissionAssigned = "PermissionAssigned"
}

export interface OperationRequestSummary {
  id: string,
  identityType: IdentityType
  identityId: string
  email: string
  displayName: string
  tenantId?: string
  tenantName: string
  creationDate: Date
  modificationDate: Date
  status: OperationRequestStatus
  operationType: OperationType,
  isMine: boolean,
  canApprove: boolean,
  approvers : string[]

}

export interface UpsertPermissionAssigned {
  identityType: IdentityType
  identityId: string
  tenantId?: string
  roles: TenantMemberRoleBody[]
}


export interface AproveRejectOperationRequest {
  OperationRequestId: string
  CreatedById?: string
  CreatedByDisplayName?: string
}

export interface HistoryOperationRequest {
  OperationRequestId: Guid
}

export class OperationRequestStore extends DataStore<OperationRequestSummary> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/admin/operationRequests`, [])
    this.publicUrl = baseUrl
  }

  public async addNewUser(data: UserItem) {
    var url = `${this.baseUrl}`
    return await this.handleCallAsync(async () => {
      var result = await this.httpService.post<UserItem, CommandResult<any>>(url, data)
      return result.data
    })
  }

  public async addNewUserNoDraft(data: UserItem) {
    var url = `${this.baseUrl}/createUser`
    return await this.handleCallAsync(async () => {
      var result = await this.httpService.post<UserItem, CommandResult<any>>(url, data)
      return result.data
    })
  }

  public async getHistory(data: string) {
    var url = `${this.baseUrl}/history/${data}`
    const response = await this.httpService.get<QueryResult<HistoryOperationRequest>>(url)
    return response.data.items || []
  }
  public async approveInBulk(data: string[]) {
    var url = `${this.baseUrl}/approveInBulk`
    return await this.handleCallAsync(async () => {
      var result = await this.httpService.post<string[], CommandResult<any>>(url, data)
      return result.data
    })
  }
  public async rejectInBulk(data: string[]) {
    var url = `${this.baseUrl}/rejectInBulk`
    return await this.handleCallAsync(async () => {
      var result = await this.httpService.post<string[], CommandResult<any>>(url, data)
      return result.data
    })
  }

  public async aproveOperation(data: AproveRejectOperationRequest) {
    var url = `${this.baseUrl}/approve/${data.OperationRequestId}`
    return await this.handleCallAsync(async () => {
      var result = await this.httpService.post<AproveRejectOperationRequest, CommandResult<any>>(url, data)
      return result.data
    })
  }

  public async rejectOperation(data: AproveRejectOperationRequest) {
    var url = `${this.baseUrl}/reject/${data.OperationRequestId}`
    return await this.handleCallAsync(async () => {
      var result = await this.httpService.post<AproveRejectOperationRequest, CommandResult<any>>(url, data)
      return result.data
    })
  }

  public async cancelOperation(data: AproveRejectOperationRequest) {
    var url = `${this.baseUrl}/cancel/${data.OperationRequestId}`
    return await this.handleCallAsync(async () => {
      var result = await this.httpService.post<AproveRejectOperationRequest, CommandResult<any>>(url, data)
      return result.data
    })
  }

}


export class UpsertPermissionAssignedStore extends FormStore<UpsertPermissionAssigned, UpsertPermissionAssigned> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/admin/operationRequests`)
    this.publicUrl = baseUrl
  }

  public async update(applicationId: string, item: UpsertPermissionAssigned) {
    return this.save(applicationId, item)
  }
}



