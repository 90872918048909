import DataStore, { Query, QueryResult } from 'src/core/stores/data-store'
import FormStore from 'src/core/stores/form-store'
import { CommandResult } from 'src/core/stores/types'


export enum AppStatus {
  Running = 'Running',
  Stopped = "Stopped",
  Maintenance = "Maintenance",
}

export interface ApplicationSummary {
  id: string,
  enabled: boolean,
  name: string,
  displayName: string,
  description: string,
  status : AppStatus
}

export interface ApplicationReference {
  id: string
  name: string
}

export interface NewApplicationItem {
  enabled: boolean,
  name: string,
  displayName: string,
  description: string,
  sharedSecret: string,
  redirectUris: string,
  hideInUserList: boolean
}

export interface ApplicationItem {
  enabled: boolean,
  id: string,
  name: string,
  displayName: string,
  description: string,
  redirectUris: string,
  privateUrl: string,
  publicUrl: string,
  prerelease: boolean,
  icon: string,
  owners: string[],
  hideInUserList: boolean
}

export interface ApplicationWithRoles {
  id: string
  name: string
  displayName: string
  roles: AppRole[]
}

export interface AppRole {
  id: string
  name: string
  byHierarchy: boolean,
  operationRequestVisibility: boolean
  serviceManagerVisibility : boolean

}

export class ApplicationsStore extends DataStore<ApplicationSummary> {
  
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/admin/applications`, [])
    this.publicUrl = baseUrl
  }

  public async changeStatus(id: string, value: AppStatus) {
    return await this.handleCallAsync(async () => {
      var response = await this.httpService.put<any, any>(`${this.baseUrl}/${id}/${value}`, {})
      return true
    })
  }

}


export class ApplicationItemStore extends FormStore<ApplicationItem, NewApplicationItem> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/admin/applications`)
    this.publicUrl = baseUrl
  }

  public async update(applicationId: string, item: ApplicationItem) {
    return this.save(applicationId, item)
  }
}

export class ApplicationWithRoleStore extends DataStore<ApplicationWithRoles> {
  private apiUrl: string
  private apiUrlProfile: string
  constructor(baseUrl: string) {
    super(baseUrl, [])
    this.apiUrl = `${baseUrl}/api/v1/admin/applications/approles`
    this.apiUrlProfile = `${baseUrl}/api/v1/profile/applications/approles`
  }


  public async getAll(query: Partial<Query>, fromProfile: boolean = false) {
    if (fromProfile) {
      return await this.handleCallAsync(async () => {
        var response = await this.httpService.get<QueryResult<ApplicationWithRoles>>(`${this.apiUrlProfile}?${DataStore.buildUrl(query as Query)}`)
        return response.data.items
      })
    }
    else {
      return await this.handleCallAsync(async () => {
        var response = await this.httpService.get<QueryResult<ApplicationWithRoles>>(`${this.apiUrl}?${DataStore.buildUrl(query as Query)}`)
        return response.data.items
      })
    }

  }

}

