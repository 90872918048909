import { container } from 'src/inversify.config'
import DataStore, { Query, QueryResult } from '../../core/stores/data-store'
import { UserSummary } from '../users/users-store'

export class AppUsersStore extends DataStore<UserSummary> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}`, [])
    this.publicUrl = baseUrl
  }
  changeUrl(appId: string) {
    this.baseUrl = `api/v1/admin/applications/${appId}/users`
  }

}

export class AppUsersWithRolesStore extends DataStore<UserSummary> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}`, [])
    this.publicUrl = baseUrl
  }
  changeUrl(appId: string) {
    this.baseUrl = `api/v1/admin/applications/${appId}/usersWithRoles`
  }

  public async loadWithTime(query: Partial<UserSummary>) {
    return await this.handleCallAsync(async () => {
      var response = await this.httpService.get<QueryResult<UserSummary>>(`${this.baseUrl}?${DataStore.buildUrl(query as Query)}`, undefined, 5 * 60 * 1000)
      this._state.set((s) => {
        s.items = response.data.items || (response.data as any).value || []
        s.count = response.data.count || response.data['@odata.count'] || 0
        return s
      })
      return true
    })
  }

}

