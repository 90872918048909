import * as React from 'react'
import { Form, Spin, Input, Modal, Row, Col, Alert, Card, Checkbox, Select, Tabs, Button, notification } from 'antd'
import autobind from 'autobind-decorator'
import { ApplicationItemStore, ApplicationItem } from 'src/stores/applications/applications-store'
import { withTranslation, WithTranslation } from 'react-i18next'
import ContentTitle from 'src/core/ui/content-header'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import Roles from './roles/role-list'
import Users from './users/user-list'
import { nameof } from 'src/core/utils/object'
import { clone } from 'src/core/utils/object'
import TextArea from 'antd/lib/input/TextArea'
import OwnersSelector from 'src/components/ownerSelector'
import AppPermissions from './permissions/permission-list'
import Dragger from 'antd/lib/upload/Dragger'
import { container } from '../../../inversify.config'
import { useEffect, useMemo, useRef, useState } from 'react'
import FormItem from 'antd/lib/form/FormItem'
import Icon, { CheckOutlined, InboxOutlined } from '@ant-design/icons'
import FloatInput from '../../../core/ui/awesome/floatComponent/input'
import FloatTextArea from '../../../core/ui/awesome/floatComponent/textArea'
const { TabPane } = Tabs

interface ApplicationViewProps extends WithTranslation, RouteComponentProps<RouterParameters> {

}

interface ApplicationViewState {
  newUserShown: boolean
  iconUrl: string
}

interface RouterParameters {
  id: string
  tab: string
}

const ApplicationView: React.FC<ApplicationViewProps> = (props) => {
  const { t } = props
  const currentStore = useMemo(() => container.get(ApplicationItemStore), [])
  const currentState = currentStore.state
  const [form] = Form.useForm<ApplicationItem>()
  const [iconUrl, setIconUrl] = useState<string | undefined>()
  const inputRef = useRef()
  const [defaulActiveKey, setDefaulActiveKey] = useState("1")
  const [applicationId] = useState(props.match.params.id)
  const [initialValues, setInitialValues] = useState<ApplicationItem | undefined>()
  useEffect(
    () => {
      load()
    }, [inputRef]
  )

  useEffect(
    () => {
      if (props.match.params.tab == "users") {
        setDefaulActiveKey("2")
      }
    }, [props.match.params.tab]
  )
  const load = async () => {
    await currentStore.load(applicationId)
    setInitialValues(currentState.item.get())
    setIconUrl(currentState.item.get().icon)
  }
  const onUpdate = async () => {
    let item: ApplicationItem
    try {
      item = (await form.validateFields()) as ApplicationItem
      item.owners = item.owners.map(x=>(x as any).id ?? x)
      notification['success']({
        message: t("Changes have been saved"),
        icon: <CheckOutlined style={{ color: '#108ee9' }} />,
      });
    } catch {
      return
    }
    let response = await currentStore.update(applicationId, item)
   
  }

  const getBase64 = (file: File) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      setIconUrl(reader.result as string)

      form.setFieldsValue({
        icon: reader.result as string
      })
    })
    reader.readAsDataURL(file)

    return false
  }




  return <div>
    <ContentTitle type={t('Application')} title={initialValues?.name} />
    <Spin spinning={currentState.isBusy.get()}>
      {initialValues &&
        <Row gutter={16}>
          <Col span={8}>
            <Card title={t("General properties")}>

            <Form form={form} layout={'vertical'} initialValues={initialValues}>
                <FormItem  name='name'>
                  <FloatInput label={t("Application Id")} readOnly={true} />
                </FormItem>
                <FormItem 
                  name={nameof<ApplicationItem>('displayName')}
                  rules={[{ required: true, message: t('Please input the application display name!') }]}>
                  <FloatInput required label={t("Display name")} placeholder={t("First name")} />
                </FormItem>
                <FormItem 
                  name={nameof<ApplicationItem>('owners')}

              >
                  <OwnersSelector label={t("Owners")}  valueAsItemReference multiple appId={applicationId} placeholder={t("Owners")} />
                </FormItem>
                <FormItem 
                  name={nameof<ApplicationItem>('description')}
                  rules={[{ required: false, message: t('Please input the application description!') }]}
                >

                  <FloatTextArea label={t("Description")} placeholder={t("Description")} />
                </FormItem>
                <FormItem
                  name={nameof<ApplicationItem>('privateUrl')}
                  rules={[{ required: true, message: t('Please input the application private URL!') }]}>
                  <FloatInput required label={t("App URI")} placeholder={t("Private url")} />
                </FormItem>
                <FormItem 
                  name={nameof<ApplicationItem>('publicUrl')}>

                  <FloatInput label={t("Public URI")} placeholder={t("Public URI")} />
                </FormItem>
                <FormItem 
                  name={nameof<ApplicationItem>('redirectUris')}
                  rules={[{ required: true, message: t('Please input the application redirect URIs!') }]}>

                  <FloatTextArea label={t("Redirect URIs")} required placeholder={"https://tempuri.org"} />

                </FormItem>
                <FormItem
                  name={nameof<ApplicationItem>('prerelease')}
                  valuePropName='checked'>
                  <Checkbox><span>{t("This appplication is in PRE-RELEASE mode")}</span></Checkbox>
                </FormItem>
                <FormItem 
                  name={nameof<ApplicationItem>('icon')}>
                  <FloatInput label={t("Icon")} placeholder={t("Icon (Ant Icon, URL or base64 image)")}
                    onChange={e => setIconUrl(e.target.value)} />
                </FormItem>
                <FormItem name={'icon-load'}>
                  <Dragger
                    showUploadList={false}
                    beforeUpload={file => getBase64(file)}>
                    {iconUrl && <img width="80px" src={iconUrl} />}
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      {t("Click or drag file to update the app picture")}
                    </p>
                  </Dragger>
                </FormItem>
                <FormItem name={nameof<ApplicationItem>('enabled')}
                  valuePropName='checked'>
                  <Checkbox><span>{t("Enable the application")}</span></Checkbox>
                </FormItem>
                <FormItem name={nameof<ApplicationItem>('hideInUserList')}
                  valuePropName='checked'>
                  <Checkbox><span>{t("Hide in user List")}</span></Checkbox>
                </FormItem>
                <div
                  style={{
                    textAlign: 'right'
                  }}
                >
                  <Button onClick={() => { form.resetFields() }} style={{ marginRight: 8 }}>
                    {t('Cancel')}
                  </Button>
                  <Button type="primary" htmlType="submit" onClick={() => onUpdate()} loading={currentState.isBusy?.value} >
                    {t('Save')}
                  </Button>
                </div>
              </Form>
            </Card>
          </Col>
          <Col span={16}>
            <Tabs  type="card">
            <TabPane tab={t('Roles')} key="1" style={{ zIndex: 0 }}>
              <Roles applicationId={applicationId}  />
              </TabPane>
              <TabPane tab={t('Users')} key="2" style={{ zIndex: 0 }}>
              <Users applicationId={applicationId} />
            </TabPane>
            </Tabs> 
          </Col>
        </Row>
      }
    </Spin>

  </div>
}

export default withRouter(withTranslation()(ApplicationView))
